import { useRef, useEffect } from 'react';

import { useAppDispatch } from '@/core/redux/hooks';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';
import { cn } from '@/utils/cn';
import { reportMessage } from '@/utils/sentry';

import LoadingSpinner from '../LoadingSpinner';

import type { AppThunk } from '@/core/redux/types';

interface Props {
    width?: number;
    className?: string;
    fetchNextPageFn?: () => AppThunk;
    onFetchMore?: () => void;
}

const LoadMore = ({ width, className, fetchNextPageFn, onFetchMore }: Props) => {
    const dispatch = useAppDispatch();
    const observerRef = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(observerRef, {});

    // Fetch more contacts when the observer is visible
    useEffect(() => {
        if (!fetchNextPageFn) {
            reportMessage({
                message: 'Attempt to fetch more data without a fetch function',
                source: 'loadMore',
            });
        }

        if (entry?.isIntersecting && fetchNextPageFn) {
            dispatch(fetchNextPageFn());
        }

        if (entry?.isIntersecting && onFetchMore) {
            onFetchMore();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry?.isIntersecting]);

    return (
        <div
            ref={observerRef}
            className={cn(
                'sticky left-0 flex w-full items-center justify-center',
                className || 'h-60',
            )}
            style={{ width }}
        >
            <LoadingSpinner className="text-gray-400" size="small" />
        </div>
    );
};

export default LoadMore;
