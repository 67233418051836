import { Command } from 'cmdk';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useCallback, useRef } from 'react';
import { useEffect, useState } from 'react';
import { useClickAway } from 'react-use';

import { getOpen, openMenu, getSearch } from '@/app/cmdk/models/menu';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import { getIsCmdK } from '../../helper';
import { fetchCampaigns } from '../../models/campaigns';
import { CommandMenuType } from '../../types';

import type { ReactNode } from 'react';

export interface Props {
    children: ReactNode;
    hide: () => void;
}

const MenuWrapper = ({ children, hide }: Props) => {
    const dispatch = useAppDispatch();
    const { pathname } = useRouter();
    const isOpen = useAppSelector(getOpen);
    const search = useAppSelector(getSearch);
    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);

    const [value, setValue] = useState('[account-settings]');

    const ref = useRef<HTMLDivElement>(null);

    useClickAway(ref, hide);

    // Toggle the menu when ⌘K is pressed
    const handleKeydown = useCallback(
        (evt: KeyboardEvent) => {
            if (getIsCmdK(evt) && canUpdate) {
                if (!isOpen) {
                    const isWorkspaceRoute = pathname.includes('/workspaces');

                    dispatch(
                        openMenu(
                            isWorkspaceRoute ? CommandMenuType.workspace : CommandMenuType.default,
                        ),
                    );
                } else {
                    hide();
                }
            }
        },
        [canUpdate, dispatch, hide, isOpen, pathname],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [canUpdate, dispatch, handleKeydown, hide, isOpen]);

    useEffect(() => {
        // Fetch campaigns
        if (isOpen) {
            dispatch(fetchCampaigns(canUpdate));
        }
    }, [dispatch, search, isOpen, canUpdate]);

    return (
        <AnimatePresence initial={false}>
            {isOpen && (
                <div className="relative z-modal">
                    <motion.div
                        className="fixed inset-0 bg-gray-800/50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.1 } }}
                        exit={{ opacity: 0, transition: { duration: 0.1 } }}
                    />

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <Command
                                value={value}
                                onValueChange={(value) => {
                                    setValue(value);
                                }}
                                label="Global Command Menu"
                                className="w-full"
                            >
                                <motion.div
                                    className="mx-auto w-full max-w-screen-sm rounded-xl bg-white text-gray-800 shadow-xl"
                                    ref={ref}
                                    initial={{ opacity: 0, scale: 0.95 }}
                                    animate={{
                                        opacity: 1,
                                        scale: 1,
                                        transition: { duration: 0.1 },
                                    }}
                                    exit={{
                                        opacity: 0,
                                        scale: 0.95,
                                        transition: { duration: 0.1 },
                                    }}
                                >
                                    {children}
                                </motion.div>
                            </Command>
                        </div>
                    </div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default MenuWrapper;
