import { useRouter } from 'next/router';

import { useWorkspaces } from './useWorkspaces';
import { LS_LAST_ACTIVE_WORKSPACE_ID_KEY } from '../constants';

const isWorkspaceRoute = (pathname: string) => pathname.startsWith('/workspaces/[id]');

export const useGetWorkspacePathname = () => {
    const { pathname } = useRouter();
    const { activeWorkspace } = useWorkspaces();
    const activeWorkspaceId =
        activeWorkspace?.id || localStorage.getItem(LS_LAST_ACTIVE_WORKSPACE_ID_KEY);

    const getFullWorkspacePathnameById = (workspaceId: string) => {
        if (isWorkspaceRoute(pathname)) {
            return pathname.replace('[id]', workspaceId);
        }

        return `/workspaces/${workspaceId}`;
    };

    const getHomeWorkspacePathnameById = (workspaceId: string) => {
        return `/workspaces/${workspaceId}`;
    };

    const getHomeWorkspacePathname = () => {
        if (!activeWorkspaceId) {
            return '/workspaces';
        }

        return `/workspaces/${activeWorkspaceId}`;
    };

    return {
        getFullWorkspacePathnameById,
        getHomeWorkspacePathnameById,
        getHomeWorkspacePathname,
    };
};
