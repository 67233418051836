import { ROUTES } from '@/app/campaigns/constants';
import {
    COMMAND_LINKS,
    COMMAND_MENU_DEFAULT_SECTIONS,
    COMMAND_MENU_WORKSPACE_SECTIONS,
    GENERAL_COMMANDS,
    HELP_COMMANDS,
} from '@/app/cmdk/constants';
import { SEARCH_DEBOUNCED_WAIT } from '@/app/crm/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { Command } from 'cmdk';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';

import { preloadCampaign } from '@/app/campaigns/models/campaigns';
import { parseCommand } from '@/app/cmdk/helper';
import { getLoading } from '@/app/cmdk/models/campaigns';
import { getOpen, getType, setOpen, setSearch } from '@/app/cmdk/models/menu';
import { getCrmUrl } from '@/app/crm/helpers';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import { useUserCapabilities, CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';
import { handleIntercomLoadError } from '@/utils/intercom';

import Footer from './Footer';
import Header from './Header';
import MenuWrapper from './MenuWrapper';
import { CommandGroup } from './sections/CommandGroup';
import RecentFunnels from './sections/RecentFunnels';
import WorkspaceFunnels from './sections/WorkspaceFunnels';
import { CommandMenuSection, CommandMenuType } from '../../types';

import type { KeyboardEvent } from 'react';

const CommandMenu = () => {
    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();
    const router = useRouter();

    const open = useAppSelector(getOpen);
    const type = useAppSelector(getType);
    const loading = useAppSelector(getLoading);

    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);

    const hide = useCallback(async () => {
        dispatch(setOpen(false));
    }, [dispatch]);

    const handleSearch = useCallback(
        (value: string) => {
            dispatch(setSearch(value));
        },
        [dispatch],
    );

    const triggerSearch = useMemo(
        () => debounce(handleSearch, SEARCH_DEBOUNCED_WAIT),
        [handleSearch],
    );

    const handleSelect = useCallback(
        async (value: string, isExternal?: boolean) => {
            const command = parseCommand(value);

            // Tracking
            track(TRACKING_EVENTS.cmdk.commandUsed, { command });

            if (command.includes('funnel-')) {
                const campaignId = command.replace('funnel-', '');

                const funnelUrl = canUpdate ? ROUTES.editor(campaignId) : getCrmUrl(campaignId);

                canUpdate && dispatch(preloadCampaign(campaignId));

                await router.push(funnelUrl);

                return hide();
            }

            if (command.includes('article-')) {
                const url = command.replace('article-', '');

                window.open(url, '_ blank');

                return hide();
            }

            if (command.includes('support')) {
                if (window.Intercom && typeof window.Intercom === 'function') {
                    window.Intercom('show');
                } else {
                    handleIntercomLoadError();
                }

                return hide();
            }

            if (COMMAND_LINKS[command]) {
                if (isExternal) {
                    window.open(COMMAND_LINKS[command], '_ blank');
                } else {
                    void router.push(COMMAND_LINKS[command]);
                    void hide();
                }
            }
        },
        [canUpdate, dispatch, hide, router],
    );

    const itemOnKeyDown = useCallback(
        async (evt: KeyboardEvent<HTMLDivElement>, value: string, isExternal?: boolean) => {
            if (evt.key === 'Enter' && open) {
                evt.preventDefault();

                await handleSelect(value, isExternal);
            }
        },
        [handleSelect, open],
    );

    const sections = useMemo(
        () =>
            type === CommandMenuType.workspace
                ? COMMAND_MENU_WORKSPACE_SECTIONS
                : COMMAND_MENU_DEFAULT_SECTIONS,
        [type],
    );

    return (
        <MenuWrapper hide={hide}>
            <Header handleSearch={triggerSearch} />

            {/* Body */}
            <Command.List className="overflow-y-auto text-sm">
                <div className="flex h-[432px] flex-col space-y-2">
                    <Command.Empty className="flex h-24 w-full items-center justify-center p-4 text-lg text-gray-400">
                        {!loading && t('cmdk-no-results')}
                    </Command.Empty>

                    {sections.map((section) => {
                        switch (section) {
                            case CommandMenuSection.general:
                                return (
                                    <CommandGroup
                                        key={CommandMenuSection.general}
                                        title={t('cmdk-general')}
                                        commands={GENERAL_COMMANDS}
                                        onSelect={handleSelect}
                                        itemOnKeyDown={itemOnKeyDown}
                                        isAdmin={canUpdate}
                                    />
                                );
                            case CommandMenuSection.help:
                                return (
                                    <CommandGroup
                                        key={CommandMenuSection.help}
                                        title={t('cmdk-help')}
                                        onSelect={handleSelect}
                                        itemOnKeyDown={itemOnKeyDown}
                                        commands={HELP_COMMANDS}
                                        isAdmin={canUpdate}
                                    />
                                );
                            case CommandMenuSection.recentFunnels:
                                return (
                                    <RecentFunnels
                                        key={CommandMenuSection.recentFunnels}
                                        handleSelect={handleSelect}
                                        itemOnKeyDown={itemOnKeyDown}
                                    />
                                );
                            case CommandMenuSection.workspaceFunnels:
                                return (
                                    <WorkspaceFunnels
                                        key={CommandMenuSection.workspaceFunnels}
                                        handleSelect={handleSelect}
                                        itemOnKeyDown={itemOnKeyDown}
                                    />
                                );
                            default:
                                return null;
                        }
                    })}
                </div>
            </Command.List>

            <Footer />
        </MenuWrapper>
    );
};

export default CommandMenu;
