import { NAME } from '@/app/navigation/constants';

import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { Tooltip } from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { ReactNode } from 'react';

interface NavbarItemBaseProps {
    icon: ReactNode;
    activeIcon?: ReactNode;
    labelKey: string;
    active?: boolean;
    onClick?: () => void;
    size?: 'icon' | 'default';
    className?: string;
}

interface NavbarItemLinkProps extends NavbarItemBaseProps {
    component?: 'link';
    href: string;
}

interface NavbarItemButtonProps extends NavbarItemBaseProps {
    component: 'button';
}

export type NavbarItemProps = NavbarItemLinkProps | NavbarItemButtonProps;

// Use Navbar and NavbarItem component to create a navigation bar
export const NavbarItem = ({
    component = 'link',
    icon,
    activeIcon,
    labelKey,
    active = false,
    size = 'default',
    className = '',
    onClick,
    ...props
}: NavbarItemProps) => {
    const { t } = useTranslation(NAME);
    const navbarItemClassName = cn(
        'flex h-10 items-center gap-2 rounded-lg px-3 py-2.5 transition',
        {
            'text-gray-500 hover:bg-gray-100 hover:text-gray-800': !active,
            'bg-gray-100 font-medium text-gray-800': active,
        },
    );
    const label = t(labelKey);
    const showLabel = size !== 'icon';

    const handleClick = () => {
        onClick?.();
    };

    switch (component) {
        case 'link':
            return (
                <Tooltip content={label} placement="bottom" className="hidden max-lg:block">
                    <Link
                        href={(props as NavbarItemLinkProps).href}
                        className={cn(navbarItemClassName, className)}
                        onClick={handleClick}
                    >
                        {active && activeIcon ? activeIcon : icon}
                        {showLabel && <span className="text-sm max-lg:hidden">{label}</span>}
                    </Link>
                </Tooltip>
            );
        case 'button':
            return (
                <Tooltip content={label} placement="bottom">
                    <button
                        className={cn(navbarItemClassName, className)}
                        onClick={handleClick}
                        aria-label={label}
                        data-testid={labelKey}
                    >
                        {active && activeIcon ? activeIcon : icon}
                        {showLabel && <span className="text-sm max-lg:hidden">{label}</span>}
                    </button>
                </Tooltip>
            );
        default:
            return null;
    }
};
